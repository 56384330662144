export async function useGetBlobUrl(url: string) {
  try {
    const config = useRuntimeConfig()
    const data = await fetch(url, {
      headers: {
        Authorization: config.public.tokenMediaUrl,
      },
    })
    const blob = await data.blob()
    return URL.createObjectURL(blob)
  }
  catch (error) {
    console.log(error)
  }
}
